import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Map } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer, PolygonLayer } from '@deck.gl/layers';
import { LightingEffect, AmbientLight, _SunLight as SunLight } from '@deck.gl/core';
import { scaleThreshold } from 'd3-scale';
import data from "C:\\Users\\Owner\\projects\\koboro\\koboro\\src\\r1_taxes_green_lake.geojson"
import { useEffect } from 'react';



// Source data GeoJSON
const DATA_URL =
  data;

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json';

export const COLOR_SCALE = scaleThreshold()
  .domain([0, 500, 1000, 1500, 2000, 3000, 5000, 8000, 13000, 21000, 34000, 55000, 60000])
  .range([
    [65, 182, 196],
    [127, 205, 187],
    [199, 233, 180],
    [237, 248, 177],
    // zero
    [255, 255, 204],
    [255, 237, 160],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [189, 0, 38],
    [128, 0, 38]
  ]);

  function generateLegendItems(colorScale) {
    const domain = colorScale.domain();
    const legendItems = [];
    for (let i = 0; i < domain.length - 1; i++) {
      legendItems.push({
        color: colorScale(domain[i]),
        range: [domain[i], domain[i + 1]],
      });
    }
    return legendItems;
  }

const INITIAL_VIEW_STATE = {
  latitude: 43.8041,
  longitude: -88.9601,
  zoom: 12,
  maxZoom: 15,
  pitch: 55,
  bearing: 0
};


const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const dirLight = new SunLight({
  timestamp: Date.UTC(2019, 7, 1, 22),
  color: [255, 255, 255],
  intensity: 1.0,
  _shadow: true
});

const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];

function getTooltip({ object }) {
  return (
    object && {
      html: `\
  <div><b>Property Taxes Paid 2022</b></div>
  <div> ${formatNumberAsDollars(object.properties.TAX_PAID)}</div>
  <div>${object.properties.AcresGIS} / Acres</div>
  `
    }
  );
}

function formatNumberAsDollars(number) {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid Number";
  }

  // Convert the number to a string and split it at the decimal point
  const parts = number.toFixed(2).toString().split(".");

  // Add commas to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Rejoin the parts with a decimal point and return as a dollar-formatted string
  return `$${parts.join(".")}`;
}

export default function App({ data = DATA_URL, mapStyle = MAP_STYLE }) {
  const legendItems = generateLegendItems(COLOR_SCALE);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);

  const toggleInfoBox = () => {
    setInfoBoxVisible(!infoBoxVisible);
  };

  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ ambientLight, dirLight });
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });

  const layers = [
    // only needed when using shadows - a plane for shadows to drop on
    new PolygonLayer({
      id: 'ground',
      data: landCover,
      stroked: false,
      getPolygon: f => f,
      getFillColor: [0, 0, 0, 0],
      antialiasing: true
    }),
    new GeoJsonLayer({
      id: 'geojson',
      data,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      getElevation: f => Math.sqrt(f.properties.TAX_PAID) * 10,
      getFillColor: f => COLOR_SCALE(f.properties.TAX_PAID),
      getLineColor: [255, 255, 255],
      pickable: true,
      antialiasing: true,
      autoHighlight: true
    })
  ];

  return (
    <div>
        <div className={`info-box ${infoBoxVisible ? 'open' : ''}`}>
        <div className="info-header" onClick={toggleInfoBox}>
          Green Lake Property Value
        </div>
        {infoBoxVisible && (
          <div className="legend">
            {legendItems.map((item, index) => (
              <div key={index} className="legend-item">
                <div
                  className="legend-color"
                  style={{ backgroundColor: `rgb(${item.color.join(', ')})` }}
                ></div>
                <div className="legend-text">
                  <span>{item.range[0]}</span> - <span>{item.range[1]}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <DeckGL
        layers={layers}
        effects={effects}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        getTooltip={getTooltip}
      >
        <Map reuseMaps mapLib={maplibregl} mapStyle={mapStyle} preventStyleDiffing={true} />
      </DeckGL>
    </div>

  );
}

